import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { giveQuest, giveQuestReward } from "../../../stores/NetworkStore"
import "./quest.css"
import { Role, Title } from "../../../../../types"
import { Modal } from "../modal/modal"
import { addIconsToDescription } from "../../utils/descriptions"
import { RarityColor } from "../../../../../types/Config"
import { Rarity } from "../../../../../types/enum/Game"
import { bold } from "discord.js"
import { Pkm } from "../../../../../types/enum/Pokemon"
import PokemonFactory from "../../../../../models/pokemon-factory"
import { getPokemonData } from "../../../../../models/precomputed/precomputed-pokemon-data"

export function Quest(props: {show: boolean, onClose: () => void}) {
  const { t } = useTranslation()
  const user = useAppSelector((state) => state.network.profile)
  const questData = user ? user.questData : []
  const numUnlock = (user && (user.titles.indexOf(Title.BACKER) > -1 || user.role === Role.ADMIN ? 3 : user.level > 50 ? 2 : 1)) as number

  return <Modal
      show={props.show}
      onClose={props.onClose}
      className="quest"
      header={t("quest")}
      body={
      <ul>
        {
          [0, 1, 2].map((questSlot) => (
            <li key={"quest" + questSlot} className="quest-box">
              {
                questData[questSlot] ?
                [
                  <h2 key={"head" + questSlot}>{"[ " + t("quests." + questData[questSlot].questType) + " ]"}</h2>,
                  [questData[questSlot].isHard ? <strong key={"isHard" + questSlot}>{t("quests.difficulty") + t("quests.hard")} </strong> :  null],
                  <p key={"desc" + questSlot}>{addIconsToDescription(t("quests." + questData[questSlot].questType + "_desc"))}</p>,
                  <br key={"br" + questSlot}></br>,
                  <strong key={"objective" + questSlot} >{t("quests.quest_objective")}</strong>,
                  questData[questSlot].questInfo.length > 0 ? 
                  questData[questSlot].questInfo.map((data, i) => (
                    <p key={questSlot + "obj" + i}>{"- "}{addIconsToDescription(t("quests." + questData[questSlot].questType + "_obj", {info: Pkm[data] ? ('"' + data.charAt(0).toUpperCase() + data.slice(1).toLowerCase() + '"') : data, number: questData[questSlot].questInfoNumber[i] ? questData[questSlot].questInfoNumber[i] : 0}))}</p>
                  ))  : questData[questSlot].questInfoNumber.length > 0 ? 
                    questData[questSlot].questInfoNumber.map((data, i) => (
                      <p key={questSlot + "obj" + i}>{"- "}{addIconsToDescription(t("quests." + questData[questSlot].questType + "_obj", {info: questData[questSlot].questInfo[i] ? questData[questSlot].questInfo[i] : 0, number: data}))}</p>
                  )) : <p key={questSlot + "obj"}>{addIconsToDescription(t("quests." + questData[questSlot].questType + "_obj"))}</p>,
                  <p key={"obj" + questSlot} className="objective-reward">{addIconsToDescription(t("quests.quest_progress", {count: questData[questSlot].objectiveCount === -1 ? questData[questSlot].objectiveTarget : Math.min(questData[questSlot].objectiveCount, questData[questSlot].objectiveTarget), target: questData[questSlot].objectiveTarget})) + "\n" 
                    + addIconsToDescription(t("quests.quest_reward", {exp: questData[questSlot].expReward}))}</p>
                ]
                : <h2 key={"empty" + questSlot}> {t("quests.quest_empty")}</h2>
                }
                <QuestContentButton 
                  questSlot={questSlot}
                />           
            </li>
          ))   
        }
      </ul>
      }
    />
}

function QuestContentButton(props: {questSlot: number}){
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.network.profile)
  const isBacker = user ? user.titles.indexOf(Title.BACKER) > -1 : false
  const questData = user ? user.questData[props.questSlot] : null
  const isFinish = questData ? questData.objectiveCount >= questData.objectiveTarget : false
  const isRewardTaken = questData ? questData.objectiveCount == -1 : false
  const firstDate = questData ? questData.startDate : new Date()
  const nowDate = new Date()
  const timeStartDiffMinute = questData ? Math.floor(((nowDate.getTime() - firstDate.getTime()) / (1000 * 60))) : 0
  const timeUntilReset = questData && timeStartDiffMinute > 0 ? (isBacker ? (8 * 60) - timeStartDiffMinute : (12 * 60) - timeStartDiffMinute) : 0
  const isResetAble = questData ? timeStartDiffMinute / 60 >= (isBacker ? 8 : 12) : false
  const numUnlock = (user && (user.titles.indexOf(Title.BACKER) > -1 || user.role === Role.ADMIN ? 3 : user.level > 50 ? 2 : 1)) as number
  const isLocked = numUnlock <= props.questSlot

  const resetQuestButton = user ? (
    <button
      className="bubbly orange small_button"
      onClick={() => {
        dispatch(
          giveQuest({
            uid: user.uid,
            questSlot: props.questSlot
          })
        )
      }}
    >{t("quests.quest_reset")}
    </button>
  ) : null

  const cooldownResetQuestButton = user ? (
    <button
      className="bubbly orange small_button"
      disabled={!isResetAble}
      title={t("quests.quest_cooldown_tooltip", { hour:  Math.floor(timeUntilReset / 60), minute: Math.floor(timeUntilReset % 60)})}
    >{t("quests.quest_cooldown")}
    </button>
  ) : null

  const turnQuestButton = user ? (
    <button
      className="bubbly green button"
      onClick={() => {
        dispatch(
          giveQuestReward({
            questSlot: props.questSlot
          })
        )
      }}
    >{t("quests.quest_turn")}
    </button>
  ) : null

  const questProgressingButton = user ? (
    <button
      className="bubbly green button"
      onClick={() => {
        dispatch(
          giveQuestReward({
            questSlot: props.questSlot
          })
        )
      }}
      disabled={true}
    >{t("quests.quest_progressing")}
    </button>
  ) : null

  const takenRewardButton = user ? (
    <button
      className="bubbly green button"
      disabled={isRewardTaken}
    >{t("quests.quest_taken")}
    </button>
  ) : null

  const lockedQuestButton = user ? (
    <button
      className="bubbly red full_button"
    >{t("quests.quest_locked")}
    </button>
  ) : null

  const unlockQuestButton = user ? (
    <button
      className="bubbly green full_button"
      onClick={() => {
        dispatch(
          giveQuest({
            uid: user.uid,
            questSlot: props.questSlot
          })
        )
      }}
    >{t("quests.quest_unlock")}
    </button>
  ) : null

  return isLocked ? (
    <>
    { lockedQuestButton }
    </>
  ) : questData ? (
    <>
      { isRewardTaken ? takenRewardButton
        : isFinish ? turnQuestButton
        : questProgressingButton
      }
      {
        isResetAble ? resetQuestButton :
        cooldownResetQuestButton
      }
    </>
  ) : (
    <>
      { unlockQuestButton }
    </>
  )
}